import React from "react"
import { graphql, Link } from "gatsby"
import get from 'lodash/get'
import Layout from "../components/Layout"
import { GatsbySeo, FAQJsonLd } from 'gatsby-plugin-next-seo';
import DateIcon from "../assets/svg/date.inline.svg"

class Etusivu extends React.Component {
  render() {
  const page = get(this, 'props.data.page')
  const allWpPost = get(this, 'props.data.allWpPost')

  return (
    <Layout bodyClass={`page-template-default page wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}>
      <GatsbySeo 
        title={page.seo.title} description={page.seo.metaDesc} 
        canonical={`https://www.pelipaikat.fi/`}
      />
      <FAQJsonLd
        questions={[
          { question: 'Mitä ovat pelipaikat netissä?', answer: 'Pelipaikat ovat sivustoja, jotka tarjoavat laajasti pelattavaa laidasta laitaan. Voit esimerkiksi pelata slotteja, live-pelejä tai vedonlyöntiä haluamallasi pelipaikalla. Löydät täältä kattavan listan hyviä pelipaikkoja.' },
          { question: 'Saako Suomesta pelata netissä toimivalla pelipaikalla?', answer: 'Kyllä voi! Voit pelata haluamallasi pelipaikalla käsin Suomesta. Pelaaminen ei ole missään tapauksessa laitonta, vaikka peliyhtiöt eivät saa mainostaa sivujaan Suomesta käsin.' },
          { question: 'Mistä löydän parhaat pelipaikat?', answer: 'Täältä tietenkin! Olemme koonneet sivuillemme kaikki parhaat pelipaikat, jotka ovat saaneet paljon hyvää palautetta. Voit siis valita sivujemme kautta minkä tahansa haluamasi pelipaikan, etkä varmasti tule pettymään.' },
          { question: 'Tarjoavatko pelipaikat ilmaisia etuja?', answer: 'Kyllä! Pelipaikat tarjoavat välillä ilmaisia etuja asiakkailleen. Voit tarkastaa sivujemme kautta kaikki sivut, jotka antavat uusille pelaajille ilmaisia etuja. Yritämme aina hommata lukijoillemme parhaat edut!' },
          { question: 'Miten erotan turvalliset sekä luotettavat pelipaikat?', answer: 'Turvalliset ja luotettavat pelipaikat voit tunnistaa lisenssistä. Jos pelipaikka operoi ETA-maan pelilisenssillä, voit olla varma että kyseessä on turvallinen sivusto. Yleisimpiä lisenssejä ovat muun muassa Maltan, Viron ja Iso-Britannian pelilisenssit.' },
        ]}
      />
      <article className={`frontpage page type-page status-publish hentry`}> 
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: page.title }} />
          </div>
        </header>
        <div className="post-inner thin">
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
        <h3 className="entry-title heading-size-3 header3">Uutta sisältöä</h3>
        <div className="related">
          {allWpPost.edges.map(({node})=> (
          <article key={node.databaseId} className={`post-${node.databaseId} post type-post has-post-thumbnai archive-post`} id={`post-${node.databaseId}`}>
            <header className="entry-header">
                {node.featuredImage !== null &&
                  <img src={node.featuredImage.node.mediaItemUrl} className="featured" alt={node.featuredImage.node.altText}/>
                }
                <h5 className="entry-title heading-size-5">
                  <Link to={node.uri}
                    dangerouslySetInnerHTML={{ __html: node.title }}
                  />
                </h5>
                <span className="meta-date"><DateIcon /> {node.date} | {node.categories.nodes.map(({name, uri})=><a href={uri}> {name}</a>)}</span>
            </header>
          </article>
          ))}
        </div>
        <div className="post-inner thin">
          <div className="entry-content">
            <h2>Pelipaikat FAQ – Usein kysytyt kysymykset</h2>
            <p>Monelle pelaajalle on varmasti tullut mieleen useita kysymyksiä pelipaikoista. Mitä ne ovat ja voiko pelipaikat laittaa testiin Suomesta? Saat muun muassa näihin kysymyksiin vastaukset tämän osion kautta. Pyrimme aina vastaamaan kaikkiin kysymyksiin, että mitään ei vain jäisi hampaankoloon.</p>
            <p>Jos et saa vastausta haluamaasi kysymykseen alapuolelta, kannattaa silmäillä tämän sivun tekstiä läpi. Olemme nimittäin kertoneet laajasti pelipaikoista tälle sivulle ja yritämme päivittää tekstiä mahdollisimman usein.</p>
            <p><span style={{textDecoration: 'underline'}}><strong>Mitä ovat pelipaikat netissä?</strong></span></p>
            <p>Pelipaikat ovat sivustoja, jotka tarjoavat laajasti pelattavaa laidasta laitaan. Voit esimerkiksi pelata slotteja, live-pelejä tai vedonlyöntiä haluamallasi pelipaikalla. Löydät täältä kattavan listan hyviä pelipaikkoja.</p>
            <p><span style={{textDecoration: 'underline'}}><strong>Saako Suomesta pelata netissä toimivalla pelipaikalla?</strong></span></p>
            <p>Kyllä voi! Voit pelata haluamallasi pelipaikalla käsin Suomesta. Pelaaminen ei ole missään tapauksessa laitonta, vaikka peliyhtiöt eivät saa mainostaa sivujaan Suomesta käsin.</p>
            <p><span style={{textDecoration : 'underline'}}><strong>Mistä löydän parhaat pelipaikat?</strong></span></p>
            <p>Täältä tietenkin! Olemme koonneet sivuillemme kaikki parhaat pelipaikat, jotka ovat saaneet paljon hyvää palautetta. Voit siis valita sivujemme kautta minkä tahansa haluamasi pelipaikan, etkä varmasti tule pettymään.</p>
            <p><span style={{textDecoration : 'underline'}}><strong>Tarjoavatko pelipaikat ilmaisia etuja?</strong></span></p>
            <p>Kyllä! Pelipaikat tarjoavat välillä ilmaisia etuja asiakkailleen. Voit tarkastaa sivujemme kautta kaikki sivut, jotka antavat uusille pelaajille ilmaisia etuja. Yritämme aina hommata lukijoillemme parhaat edut!</p>
            <p><span style={{textDecoration : 'underline'}}><strong>Miten erotan turvalliset sekä luotettavat pelipaikat?</strong></span></p>
            <p>Turvalliset ja luotettavat pelipaikat voit tunnistaa lisenssistä. Jos pelipaikka operoi ETA-maan pelilisenssillä, voit olla varma että kyseessä on turvallinen sivusto. Yleisimpiä lisenssejä ovat muun muassa Maltan, Viron ja Iso-Britannian pelilisenssit.</p>
          </div>
        </div>
      </article>
    </Layout>
  )
  }
}
export default Etusivu

export const query = graphql`
query FrontPage {
    page: wpPage(title: {eq: "Pelipaikat.fi"}) {
      id
      title
      content
      seo {
        metaDesc
        title
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
          slug
        }
      }
    }
    allWpPost(limit: 3) {
      edges {
        node {
          id
          title
          databaseId
          date (formatString: "DD.MM.YYYY")
          excerpt
          categories {
            nodes {
              name
              uri
            }
          }
          uri
          featuredImage {
            node {
              id
              mediaItemUrl
              altText
            }
          }
        }
      }
    }
  }
`
